import QuickReg from './components/QuickReg.vue'
import Thanks from './components/Thanks.vue'
import { createRouter, createWebHistory } from 'vue-router'


const routes = [{
        name: 'Thanks',
        component: Thanks,
         path: '/Thanks',
     },
    {
       name: 'QuickReg',
       component: QuickReg,
       path:'/',
}];

const router = createRouter({
    history: createWebHistory(process.env.path),
	alias: '*',
    routes,
})

export default router;
