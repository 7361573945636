<template>
    <nav class="navbar navbar-expand-lg bg-common text-white">
        <a class="navbar-brand text-white" href="#">Congratulations!- Your Ebook has been shared!</a>
        

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
            </ul>
            <form class="form-inline my-2 my-lg-0">
                
            </form>
        </div>
    </nav>
    <div class="main-cotainer">
        <div class="row">
            <div class="col-md-6 p-3 py-5 order-2 order-md-1">
                <h1 style="text-align: center;font-size: x-large;"><b>Thank You!</b></h1>
                <p style="text-align: center;color: black;font-size: x-large;">Your eBook has been shared </p>
                <p style="text-align: center;color: black;font-size: x-large;">through email and Whatsapp</p>
                <h5 style="text-align: center;font-size: x-large;"><b>Have a Nice Day!</b></h5> 
                <div class="row align-items-center justify-content-center">
                <div class="col-md-6 p-3 py-5 text-center order-2 order-md-1 ">
                    <div class="img-half"><a href="https://haspatal.com/meet-our-pharmacy-page-6/" target="_blank"><img src="images/pharmacy.png" /></a></div>
                </div> 
                
                <div class="col-md-6 p-3 py-5 text-center order-2 order-md-1" >
                    <div class="img-half"><a href="https://haspatal.com/pharmacies-faq/" target="_blank"><img src="images/pharmacy_store.png" /></a></div>
                </div>   
                </div>           
            </div>
            <div class="col-md-6 text-center order-1 order-md-2">
                <div class="img-half"><img src="images/eBook.png" /></div>
            </div>
        </div>
    </div>
   
</template>

<script >
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
 
export default {
        name: 'QuickReg',
        data() {
            return {
                username: '',
                email: '',
                phone: ''
            }
        },
        methods: {
            async Submit() {
				axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
				axios.defaults.headers.post['Accept'] = 'application/json';
                const submit_data = {
                    name: this.username,
                    email: this.email,
                    phone: this.phone
                }
               
                let result = await axios.post("/app/EbookSalesGrowth/eyJhbGciOiJIUz786iIsInR5cCI6IkpXVCJ9", submit_data);
                if (result.data.status == true) {
                    alert(result.data.message);
                    window.open('https://wa.me/919599661700', '_blank');
                    this.$router.push('/Login');
                } else {
                    alert(result.data.message);
                }
                
            }
        }
    }
</script>